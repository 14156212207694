<template>
  <main>
    <VoiceLnb />
    <router-view />
  </main>
</template>

<script>
import VoiceLnb from "./VoiceLnb.vue";

export default {
  components: { VoiceLnb },
};
</script>
